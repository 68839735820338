@import url('https://fonts.googleapis.com/css2?family=Overlock&family=Oxygen&family=PT+Sans+Narrow&family=Patua+One&family=Pontano+Sans&family=Port+Lligat+Slab&display=swap');

.customPopup {
    max-height: 90vh;
    width: 300px;
    padding: 0px 10px;
}

.customPopup .cancel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.customPopup .cancel button {
    outline: none;
    border: none;
    background-color: rgb(192, 0, 0);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    font-size: 20px;
}

.customPopup h1 {
    font-size: 20px;
    font-weight: bold;
    font-family: 'PT Sans Narrow', sans-serif;
}

.customPopup p {
    margin-top: 10px;
    font-size: 15px;
    font-family: 'Pontano Sans', sans-serif;
}

.customPopup form{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Oxygen', sans-serif;
}
.customPopup form label{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    height: 100px;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
}
.customPopup form input[Type="file"]{
    display: none;
}
.customPopup form input,.customPopup form textarea{
    margin-top: 10px;
    padding: 7px 10px;
    outline: none;
    border: 1px solid grey;
    border-radius: 3px;
    resize: none;
    width: 100%;
}
.customPopup form button{
    outline: none;
    border: none;
    width: 100%;
    height: 40px;
    margin: 15px 0px;
    background-color: #002993;
    color: white;
    padding: 5px;
    border-radius: 3px;
}

.customPopup form h2{
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Overlock', sans-serif;
    width: 100%;
}