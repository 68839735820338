@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');

.chart {
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width:800px) {
    .chart {
        flex-direction: column;
        align-items: center;
    }
}

.dashboardHeader {
    padding: 10px 20px;
}

.dashboardHeader .content {
    padding: 20px;
    border-radius: 10px;
    background-color: #040983;
    color: white;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.dashboardHeader .content h1 {
    font-size: 30px;
}
.dashboardHeader .content p {
    margin: 0;
}

@media screen and (max-width:600px) {
    .dashboardHeader .content h1 {
        font-size: 20px;
    }
    .dashboardHeader .content p {
        font-size: 15px;
    }
}


.companyDetails {
    padding: 10px 20px;
}

.companyDetails .content {
    padding: 20px;
    border-radius: 10px;
    background-color: green;
    color: white;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
.companyDetails .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.companyDetails .content .details div {
    font-size: 16px;
    margin: 5px 0px;
}
.companyDetails .content button{
    outline: none;
    border: none;
    padding: 5px 15px;
    font-size: 15px;
    border-radius: 5px;
    background-color: white;
    color: black;
}

@media screen and (max-width:600px) {
    .companyDetails .content div {
        font-size: 14px;
    }
}
