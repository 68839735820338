@import url('https://fonts.googleapis.com/css2?family=Rakkas&family=Rambla&display=swap');

.productsBgPage h1 {
    font-family: 'Rakkas', serif;
    padding: 0px 50px;
    margin-top: 30px;
}

.productsBgPage p {
    font-family: 'Rambla', sans-serif;
    padding: 0px 50px;
}

.productsBgPage .productBg {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    height: 300px;
    padding: 20px;
    margin: 30px 0px;
}
.productsBgPage .productBg .logo{
    width: 150px;
}
.productsBgPage .productBg .productsImages{
    display: flex;
    justify-content: space-evenly;
}
.productsBgPage .productBg .productsImages .images .image{
    height: 120px;
    width: 300px;
    margin: 10px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: 2px 2px 5px black;
}

@media screen and (max-width:900px){
    .productsBgPage .productBg .productsImages .images .image{
        width: 200px;
    }
}

@media screen and (max-width:600px){
    .productsBgPage .productBg .logo{
        width: 100px;
    }
    .productsBgPage .productBg {
        height: 200px;
    }
    .productsBgPage .productBg .productsImages .images .image{
        height: 70px;
        width: 130px;
    }
}

@media screen and (max-width:500px){
    .productsBgPage h1 {
        padding: 0px 30px;
    }
    
    .productsBgPage p {
        font-family: 'Rambla', sans-serif;
        padding: 0px 30px;
    }
    .productsBgPage .productBg .productsImages .images .image{
        height: 70px;
        width: 120px;
    }
}