@import url('https://fonts.googleapis.com/css2?family=PT+Sans&family=Palanquin+Dark&display=swap');

.ourJourney h1{
    font-family: 'Palanquin Dark', sans-serif;
    text-align: center;
    font-size: 30px;
    padding: 30px;
    padding-top: 0;
    padding-bottom: 50px;
}
.ourJourney .journeyContainer{
    background-color: rgba(0, 135, 176, 0.07);
    padding: 0px 50px;
}
.ourJourney .journeyContainer .journey{
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px;
    transform: translate(0px, -50px);
}
.ourJourney .journeyContainer .journey .content{
    width: 50%;
    margin: 10px;
    font-family: 'PT Sans', sans-serif;
    font-size: 15px;
    color: grey;
}
.ourJourney .journeyContainer .journey img{
    width: 50%;
    margin: 10px;
}

@media screen and (max-width:800px){
    .ourJourney h1{
        padding-bottom: 40px;
    }
    .ourJourney .journeyContainer{
        padding: 0px 30px;
    }
    .ourJourney .journeyContainer .journey{
        padding: 20px;
        flex-direction: column-reverse;
        align-items: center;
        transform: translate(0px, -30px);
    }
    .ourJourney .journeyContainer .journey .content{
        width: 100%;
        padding: 10px;
        margin: 0;
    }
    .ourJourney .journeyContainer .journey img{
        width: 60%;
        margin: 0;
    }
}

@media screen and (max-width:500px){
    .ourJourney .journeyContainer .journey img{
        width: 100%;
    }
}