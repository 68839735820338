@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

.adminHeader .navbar .container-fluid .leftSide{
    display: flex;
    align-items: center;
}
.adminHeader .navbar .container-fluid .leftSide button{
    border: none;
    outline: none;
    box-shadow: none;
}
.adminHeader .navbar .container-fluid .leftSide .title{
    margin-left: 10px;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
}
.adminHeader .navbar .container-fluid a{
    width: 150px;
}
.adminHeader .navbar .container-fluid a img{
    width: 100%;
    height: 100%;
}
.adminHeader .navbar .container-fluid .offcanvas .offcanvas-body{
    padding: 0;
}
.adminHeader .navbar .container-fluid .offcanvas .offcanvas-body ul li{
    background-color: #2E8BBF;
    margin: 5px 0px;
}
.adminHeader .navbar .container-fluid .offcanvas .offcanvas-body ul li:hover{
    background-color: #0d6fa8;
}
.adminHeader .navbar .container-fluid .offcanvas .offcanvas-body ul li a{
    width: 100%;
    padding: 7px 10px;
    color: white;
    font-family: 'Outfit', sans-serif;
}
.adminHeader .navbar .container-fluid .offcanvas .offcanvas-body ul h2{
    font-size: 15px;
    font-family: 'Outfit', sans-serif;
    font-weight: bold;
    padding: 0px 10px;
    margin-top: 5px;
}
.adminHeader .navbar .container-fluid .offcanvas .offcanvas-body ul li a i{
    margin-right: 10px;
}

@media screen and (max-width:500px){
    .adminHeader .navbar .container-fluid .leftSide .title{
        margin-left: 5px;
        font-size: 16px;
    }
    .adminHeader .navbar .container-fluid a{
        width: 120px;
    }
}