@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200&display=swap');

header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    background-image: linear-gradient(#DEF5FD,white);
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    /* font-weight: bold; */
}
header .logo{
    padding: 10px;
}
header .logo img{
    position: absolute;
    width: 200px;
}
header nav .navbar-nav .nav-link{
    margin: 0px 20px;
}
header nav .navbar-nav .dropdown{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
header nav .navbar-nav ul li{
    padding: 5px 0px;
}
header nav .navbar-nav ul li a{
    font-size: 20px;
    font-weight: bold;
}

@media screen and (max-width:500px){
    header .logo img{
        width: 150px;
    }
    header{
        font-size: 15px;
    }
    header nav .navbar-nav ul li a{
        font-size: 15px;
    }
}