@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

.deleteAdmin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Outfit', sans-serif;
}

.deleteAdmin .item {
    width: 100%;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.deleteAdmin .item h2 {
    padding: 0px 10px;
    font-size: 17px;
    margin-top: 5px;
}

.deleteAdmin .content {
    margin-top: 10px;
}

.deleteAdmin .content p {
    margin: 0;
    font-size: 15px;
}

.deleteAdmin .content .btns {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.deleteAdmin .content .btns button{
    outline: none;
    border: none;
    width: 100%;
    height: 40px;
    background-color: green;
    margin: 0px 3px;
    color: white;
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 3px;
    transition-duration: 0.1s;
}
.deleteAdmin .content .btns button.delete:hover{
    background-color: rgb(0, 103, 0);
}
.deleteAdmin .content .btns button.cancel{
    background-color: rgb(190, 0, 0);
}
.deleteAdmin .content .btns button.cancel:hover{
    background-color: rgb(145, 0, 0);
}