/* custom requirements css */

@import url('https://fonts.googleapis.com/css2?family=Overlock&display=swap');

.recentActivities h1{
    font-family: 'Overlock', sans-serif;
    font-weight: bold;
    font-size: 25px;
    padding: 30px;
    padding-bottom: 0;
}
.recentActivities h2{
    font-family: 'Overlock', sans-serif;
    font-size: 20px;
    padding: 10px;
    padding-bottom: 0;
}
.recentActivities .dashboardCustomRequirements {
    padding: 20px;
    padding-top: 0;
    font-family: 'Overlock', sans-serif;
    overflow: auto;
}

.recentActivities .dashboardCustomRequirements table {
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.recentActivities .dashboardCustomRequirements table thead tr {
    background-color: black;
    color: white;
}

.recentActivities .dashboardCustomRequirements table thead tr th {
    padding: 10px 20px;
}

.recentActivities .dashboardCustomRequirements table tbody tr:nth-child(even) {
    background-color: rgb(217, 217, 217);
}

.recentActivities .dashboardCustomRequirements table tbody tr td {
    padding: 10px 20px;
}

.recentActivities .dashboardCustomRequirements table tbody tr td div.image {
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.recentActivities .dashboardCustomRequirements table tbody tr td .action {
    display: flex;
    align-items: center;
}

.recentActivities .dashboardCustomRequirements table tbody tr td .action button {
    outline: none;
    border: none;
    text-decoration: none;
    background-color: blue;
    color: white;
    padding: 5px 15px;
    margin: 0px 5px;
    font-size: 13px;
    border-radius: 5px;
    transition-duration: 0.1s;
}

.recentActivities .dashboardCustomRequirements table tbody tr td .action button.viewRecommend:hover {
    background-color: rgb(0, 0, 212);
}

.recentActivities .dashboardCustomRequirements table tbody tr td .action button.view {
    background-color: green;
}

.recentActivities .dashboardCustomRequirements table tbody tr td .action button.view:hover {
    background-color: rgb(0, 94, 0);
}

@media screen and (max-width:600px) {
    .recentActivities .dashboardCustomRequirements table tbody tr td div.image {
        width: 70px;
        height: 70px;
    }

    .recentActivities .dashboardCustomRequirements table {
        font-size: 13px;
    }

    .recentActivities .dashboardCustomRequirements table tbody tr td .action button {
        margin: 0px 2px;
        padding: 3px 10px;
        font-size: 13px;
        border-radius: 5px;
    }

    .recentActivities .dashboardCustomRequirements table thead tr th {
        padding: 5px 5px;
    }

    .recentActivities .dashboardCustomRequirements table tbody tr td {
        padding: 5px 5px;
    }
}

/* quote datas css */

@import url('https://fonts.googleapis.com/css2?family=Overlock&display=swap');

.recentActivities .dashboardQuoteDatas {
    padding: 20px;
    padding-top: 0;
    font-family: 'Overlock', sans-serif;
    overflow: auto;
}

.recentActivities .dashboardQuoteDatas table {
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.recentActivities .dashboardQuoteDatas table thead tr {
    background-color: black;
    color: white;
}

.recentActivities .dashboardQuoteDatas table thead tr th {
    padding: 10px 20px;
}

.recentActivities .dashboardQuoteDatas table tbody tr:nth-child(even) {
    background-color: rgb(217, 217, 217);
}
.recentActivities .dashboardQuoteDatas table tbody tr td {
    padding: 10px 20px;
}

.recentActivities .dashboardQuoteDatas table tbody tr td .action {
    display: flex;
    align-items: center;
}

.recentActivities .dashboardQuoteDatas table tbody tr td .action button {
    outline: none;
    border: none;
    text-decoration: none;
    background-color: blue;
    color: white;
    padding: 5px 15px;
    margin: 0px 5px;
    font-size: 13px;
    border-radius: 5px;
    transition-duration: 0.1s;
}
.recentActivities .dashboardQuoteDatas table tbody tr td .action button.view {
    background-color: green;
}
.recentActivities .dashboardQuoteDatas table tbody tr td .action button.view:hover {
    background-color: rgb(0, 94, 0);
}

@media screen and (max-width:600px) {
    .recentActivities .dashboardQuoteDatas table {
        font-size: 13px;
    }

    .recentActivities .dashboardQuoteDatas table tbody tr td .action button {
        margin: 0px 2px;
        padding: 3px 10px;
        font-size: 13px;
        border-radius: 5px;
    }

    .recentActivities .dashboardQuoteDatas table thead tr th {
        padding: 5px 5px;
    }

    .recentActivities .dashboardQuoteDatas table tbody tr td {
        padding: 5px 5px;
    }
}