@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

.updateAdminForm h1{
    font-family: 'Outfit', sans-serif;
    font-size: 25px;
    text-align: center;
}
.updateAdminForm .cancel{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.updateAdminForm .cancel button{
    outline: none;
    border: none;
    background-color: rgb(192, 0, 0);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    font-size: 20px;
}
.updateAdminForm form{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Outfit', sans-serif;
}

.updateAdminForm form input{
    margin-top: 20px;
    padding: 7px 10px;
    outline: none;
    border: 1px solid grey;
    border-radius: 3px;
}
.updateAdminForm form button{
    outline: none;
    border: none;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    background-color: #002993;
    color: white;
    padding: 5px;
    border-radius: 3px;
}