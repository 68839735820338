@import url('https://fonts.googleapis.com/css2?family=Oxygen&family=Patua+One&family=Port+Lligat+Sans&display=swap');

.getQuote{
    max-height: 90vh;
}
.getQuote .cancel{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.getQuote .cancel button{
    outline: none;
    border: none;
    background-color: rgb(192, 0, 0);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    font-size: 20px;
}
.getQuote h1 {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    font-family: 'Oxygen', sans-serif;
}

.getQuote form {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font-family: 'Oxygen', sans-serif;
}

.getQuote form label {
    margin-top: 10px;
    font-size: 15px;
}

.getQuote form input, .getQuote form textarea {
    margin-top: 5px;
    padding: 5px 7px;
    outline: none;
    border: 1px solid grey;
    border-radius: 3px;
    resize: none;
}
.getQuote form .btns{
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
}
.getQuote form .btns button{
    width: 49%;
    outline: none;
    padding: 5px;
    border: 1px solid black;
    background-color: transparent;
    color: black;
}
.getQuote form .btns button.submit{
    background-color: black;
    color: white;
}