@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital@1&family=Poiret+One&display=swap');

.categories{
    padding: 10px 80px;
}
.categories h1{
    text-align: center;
    font-family: 'PT Sans', sans-serif;
    font-size: 30px;
    padding-top: 30px;
    margin: 10px;
}
.categories .items{
    display: flex;
    flex-wrap: wrap;
}
.categories .items .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    margin: 10px;
    width: 22.5%;
    overflow: hidden;
}
.categories .items .item .image{
    width: 100%;
    height: 200px;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.categories .items .item h2{
    font-family: 'Poiret One', sans-serif;
    font-size: 20px;
    margin-top: 10px;
}

@media screen and (max-width:1000px){
    .categories .items .item{
        width: 30%;
    }
}
@media screen and (max-width:800px){
    .categories{
        padding: 10px 40px;
    }
}
@media screen and (max-width:700px){
    .categories .items .item{
        width: 45%;
    }
}
@media screen and (max-width:500px){
    .categories .items .item{
        width: 100%;
    }
    .categories .items .item .image{
        width: 100%;
    }
}