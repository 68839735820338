@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.certification h1{
    font-family: 'Oswald', sans-serif;
    text-align: center;
    font-size: 30px;
    padding: 20px;
}
.certification marquee img{
    width: 100px;
    margin: 20px 100px;
}

@media screen and (max-width:800px){
    .certification marquee img{
        width: 80px;
        margin: 10px 50px;
    }
}
@media screen and (max-width:500px){
    .certification marquee img{
        margin: 10px 30px;
    }
}