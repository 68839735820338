@import url('https://fonts.googleapis.com/css2?family=PT+Sans&family=Palanquin+Dark&display=swap');

.ourFactory{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ourFactory h1{
    font-family: 'Palanquin Dark', sans-serif;
    text-align: center;
    font-size: 30px;
    padding: 20px;
    margin: 0;
}
.ourFactory img{
    width: 100%;
}
.ourFactory p{
    margin: 0;
    padding: 20px 100px;
    font-family: 'PT Sans', sans-serif;
    font-size: 15px;
    color: grey;
}

@media screen and (max-width:800px){
    .ourFactory p{
        margin: 0;
        padding: 20px 30px;
    }
}