@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Pavanam&family=Playfair+Display&display=swap');

.process h1{
    font-family: 'Oswald', sans-serif;
    text-align: center;
    font-size: 30px;
    padding: 30px;
}
.process .carousel .carousel-indicators{
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    bottom: auto;
    margin: 0;
    margin-top: 30px;
    padding: 0px 100px;
}
.process .carousel .carousel-indicators button{
    background-color: white;
    opacity: 1;
    width: 70px;
    height: 70px;
    padding: 10px;
    text-align: center;
    text-indent: 0;
    border: none;
    margin: 0;
    margin: 2px;
    border-radius: 100px;
    font-size: 13px;
    font-family: 'Playfair Display', serif;
}
.process .carousel .carousel-indicators button.active{
    background-color: black;
    color: white;
}
.process .carousel .carousel-inner .carousel-item{
    padding: 30px 100px;
    background-color: #61838C;
}
.process .carousel .carousel-inner .carousel-item .content{
    margin-top: 150px;
    color: white;
}
.process .carousel .carousel-inner .carousel-item .content h2{
    font-family: 'Playfair Display', serif;
    font-size: 30px;
}
.process .carousel .carousel-inner .carousel-item .content p{
    font-family: 'Pavanam', sans-serif;
    font-size: 20px;
    margin-top: 15px;
}

@media screen and (max-width:850px){
    .process .carousel .carousel-indicators{
        padding: 0px 50px;
    }
    .process .carousel .carousel-indicators button{
        width: 50px;
        height: 50px;
        font-size: 10px;
    }
    .process .carousel .carousel-inner .carousel-item{
        padding: 30px 50px;
    }
    .process .carousel .carousel-inner .carousel-item .content{
        margin-top: 100px;
    }
    .process .carousel .carousel-inner .carousel-item .content h2{
        font-size: 25px;
    }
    .process .carousel .carousel-inner .carousel-item .content p{
        font-size: 18px;
    }
}

@media screen and (max-width:630px){
    .process h1{
        font-size: 25px;
    }
    .process .carousel .carousel-indicators{
        padding: 0px 30px;
        justify-content: center;
    }
    .process .carousel .carousel-indicators button{
        margin: 4px;
        padding: 10px;
    }
    .process .carousel .carousel-inner .carousel-item{
        padding: 30px 30px;
    }
    .process .carousel .carousel-inner .carousel-item .content{
        margin-top: 170px;
        height: 250px;
    }
}