.success{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}
.success .lottie{
    width: 150px;
}
.success .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.success .content h1{
    margin-top: 10px;
    font-size: 17px;
    font-family: 'Patua One', serif;
}
.success .content p{
    font-size: 15px;
    font-family: 'Port Lligat Sans', sans-serif;
}
.success .content button{
    outline: none;
    border: none;
    background-color: black;
    color: white;
    padding: 10px;
    width:90%;
    font-family: 'Oxygen', sans-serif;
}