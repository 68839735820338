.sliderImage .topOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.sliderImage .topOptions button {
    outline: none;
    border: none;
    text-decoration: none;
    color: white;
    background-color: green;
    padding: 3px 15px;
    font-size: 20px;
    border-radius: 5px;
    transition-duration: 0.1s;
}

.sliderImage .topOptions button:hover {
    background-color: rgb(0, 103, 0);
}

.sliderImage .topOptions button.delete {
    background-color: rgb(167, 0, 0);
}

.sliderImage .topOptions button.delete:hover {
    background-color: rgb(133, 0, 0);
}

.sliderImage .image {
    width: 100%;
}
.sliderImage .image h3{
    text-align: center;
    font-size: 20px;
    font-family: 'Outfit', sans-serif;
}


/* add slider css */

@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

.addSlider h1 {
    font-family: 'Outfit', sans-serif;
    font-size: 25px;
    text-align: center;
}

.addSlider .cancel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.addSlider .cancel button {
    outline: none;
    border: none;
    background-color: rgb(192, 0, 0);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    font-size: 20px;
}

.addSlider form {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Outfit', sans-serif;
}

.addSlider form label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    height: 150px;
    width: 250px;
    border-radius: 5px;
    overflow: hidden;
}

.addSlider form input[Type="file"] {
    display: none;
}

.addSlider form button {
    outline: none;
    border: none;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    background-color: #002993;
    color: white;
    padding: 5px;
    border-radius: 3px;
}

/* delete slider image */

.deleteSlider {
    margin-top: 10px;
}

.deleteSlider p {
    margin: 0;
    font-size: 15px;
}

.deleteSlider .btns {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.deleteSlider .btns button {
    outline: none;
    border: none;
    width: 100%;
    height: 40px;
    background-color: green;
    margin: 0px 3px;
    color: white;
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 3px;
    transition-duration: 0.1s;
}

.deleteSlider .btns button.cancel {
    background-color: rgb(190, 0, 0);
}