@import url('https://fonts.googleapis.com/css2?family=Overlock&display=swap');

.customRequirements {
    padding: 20px;
    font-family: 'Overlock', sans-serif;
    overflow: auto;
}

.customRequirements table {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.customRequirements table thead tr {
    background-color: black;
    color: white;
}

.customRequirements table thead tr th {
    padding: 10px 20px;
}

.customRequirements table tbody tr:nth-child(even) {
    background-color: rgb(217, 217, 217);
}

.customRequirements table tbody tr td {
    padding: 10px 20px;
}

.customRequirements table tbody tr td div.image {
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.customRequirements table tbody tr td .action {
    display: flex;
    align-items: center;
}

.customRequirements table tbody tr td .action button {
    outline: none;
    border: none;
    text-decoration: none;
    background-color: blue;
    color: white;
    padding: 5px 15px;
    margin: 0px 5px;
    font-size: 13px;
    border-radius: 5px;
    transition-duration: 0.1s;
}

.customRequirements table tbody tr td .action button.viewRecommend:hover {
    background-color: rgb(0, 0, 212);
}

.customRequirements table tbody tr td .action button.view {
    background-color: green;
}

.customRequirements table tbody tr td .action button.view:hover {
    background-color: rgb(0, 94, 0);
}

@media screen and (max-width:600px) {
    .customRequirements table tbody tr td div.image {
        width: 70px;
        height: 70px;
    }

    .customRequirements table {
        font-size: 13px;
    }

    .customRequirements table tbody tr td .action button {
        margin: 0px 2px;
        padding: 3px 10px;
        font-size: 13px;
        border-radius: 5px;
    }

    .customRequirements table thead tr th {
        padding: 5px 5px;
    }

    .customRequirements table tbody tr td {
        padding: 5px 5px;
    }
}