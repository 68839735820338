@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200&display=swap');

.carouselPage .carousel .carousel-item{
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #00524b;
    background-position: center;
    height: 400px;
}
.carouselPage .carousel .carousel-item .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 50%;
    padding: 50px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.459);
    height: 100%;
    color: white;
    font-family: 'Outfit', sans-serif;
}
.carouselPage .carousel .carousel-item .content h1{
    font-size: 25px;
}
.carouselPage .carousel .carousel-item .content p{
    font-size: 20px;
}
.carouselPage .carousel .carousel-item .content a{
    display: inline-block;
    font-size: 20px;
    background-color: #DEF5FD;
    padding: 10px 20px;
    border-radius: 100px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    margin-top: 10px;
}

@media screen and (max-width:900px){
    .carouselPage .carousel .carousel-item{
        height: 300px;
    }
    .carouselPage .carousel .carousel-item .content{
        width: 70%;
    }
    .carouselPage .carousel .carousel-item .content h1{
        font-size: 20px;
    }
    .carouselPage .carousel .carousel-item .content p{
        font-size: 15px;
    }
    .carouselPage .carousel .carousel-item .content a{
        font-size: 15px;
    }
}
@media screen and (max-width:600px){
    .carouselPage .carousel .carousel-item{
        height: 300px;
    }
    .carouselPage .carousel .carousel-item .content{
        width: 100%;
        padding: 30px;
    }
    .carouselPage .carousel .carousel-item .content a{
        font-size: 13px;
        margin: 0;
    }
}