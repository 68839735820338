@import url('https://fonts.googleapis.com/css2?family=Outfit&family=Playfair+Display+SC&display=swap');

.adminProducts .catDropdown{
    padding: 5px 5px;
}
.adminProducts .catDropdown select{
    padding: 8px 5px;
    width: 250px;
    font-family: 'Outfit', sans-serif;
    outline: none;
    border: 1px solid grey;
    border-radius: 3px;
    background-color: white;
    box-shadow: 2px 2px 3px grey;
}

@media screen and (max-width:500px){
    .adminProducts .catDropdown select{
        width: 100%;
    }
}

/* top options design */

.adminProducts .topOptions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
}
.adminProducts .topOptions button{
    outline: none;
    border: none;
    text-decoration: none;
    color: white;
    background-color: green;
    padding: 3px 15px;
    font-size: 20px;
    border-radius: 5px;
    transition-duration: 0.1s;
}
.adminProducts .topOptions button:hover{
    background-color: rgb(0, 103, 0);
}
.adminProducts .topOptions input{
    padding: 5px 7px;
    outline: none;
    border: 1px solid grey;
    font-family: 'Outfit', sans-serif;
    border-radius: 5px;
}

@media screen and (max-width:500px){
    .adminProducts .topOptions input{
        width: 170px;
    }
}

/* table design */

.adminProducts {
    padding: 20px;
    font-family: 'Playfair Display SC', serif;
}

.adminProducts .table {
    overflow: auto;
}
.adminProducts table {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.adminProducts table thead tr {
    background-color: black;
    color: white;
}

.adminProducts table thead tr th {
    padding: 10px 20px;
}

.adminProducts table tbody tr:nth-child(even) {
    background-color: rgb(217, 217, 217);
}

.adminProducts table tbody tr td {
    width: 25%;
    padding: 10px 20px;
}

.adminProducts table tbody tr td div.image {
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.adminProducts table tbody tr td .action {
    display: flex;
    align-items: center;
}

.adminProducts table tbody tr td .action button {
    outline: none;
    border: none;
    text-decoration: none;
    background-color: blue;
    color: white;
    padding: 5px 15px;
    margin: 0px 5px;
    font-size: 13px;
    border-radius: 5px;
    transition-duration: 0.1s;
}
.adminProducts table tbody tr td .action button.edit:hover{
    background-color: rgb(0, 0, 165);
}

.adminProducts table tbody tr td .action button.delete {
    background-color: red;
}
.adminProducts table tbody tr td .action button.delete:hover {
    background-color: rgb(207, 1, 1);
}
.adminProducts table tbody tr td .action button.view {
    background-color: green;
}
.adminProducts table tbody tr td .action button.view:hover {
    background-color: rgb(0, 94, 0);
}

@media screen and (max-width:600px) {
    .adminProducts table tbody tr td div.image {
        width: 60px;
        height: 60px;
    }

    .adminProducts table {
        font-size: 13px;
    }

    .adminProducts table tbody tr td .action button {
        margin: 0px 2px;
        padding: 3px 10px;
        font-size: 13px;
        border-radius: 5px;
    }

    .adminProducts table thead tr th {
        padding: 5px 5px;
    }

    .adminProducts table tbody tr td {
        padding: 5px 5px;
    }
}